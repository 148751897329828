@use 'variables' as *;
@use 'prismjs/plugins/toolbar/prism-toolbar' as *;
@use 'prismjs/themes/prism' as *;

@import url('https://fonts.googleapis.com/css?family=Playfair+Display|Source+Sans+Pro:200,400');

@font-face {
    font-family: 'serpentine-bold';
    src: url('./assets/unicode.serpentb-webfont.woff2') format('woff2'),
        url('./assets/unicode.serpentb-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* Generic styles */
html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Source Sans Pro', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar-thumb {
    background-color: $kpg-code-background-color;
    outline: 1px solid darken($kpg-code-background-color, 5%);
    border-radius: pixel-to-rem(15px);
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 pixel-to-rem(10px) 0 $kpg-box-shadow-color;
    border-radius: pixel-to-rem(15px);
}

body::-webkit-scrollbar {
    width: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', serif;
}

.main-content {
    padding: pixel-to-rem(32px);

    & a {
        background-color: $kpg-color-orange;
        text-decoration: none;
        color: white;
        padding: 0.5rem 1rem;
        margin: 0.5rem;
        border-radius: 0.25rem;
        text-align: center;
        display: inline-block;
        transition: all 0.3s;
        &:hover {
            opacity: 0.6;
        }
    }
    & pre {
        background-color: $kpg-code-background-color;
        border-radius: 5px;

        code {
            .tag {
                color: $kpg-code-accent-color;
            }

            .attr-name {
                color: #63c1ad;
            }

            .attr-value {
                color: #e26868;
            }
        }
    }
    & pre[class*='language-'] {
        margin: 0;
        font-size: 13px;
    }
    & .inline-code {
        font-weight: bold;
        white-space: nowrap;

        &:before {
            content: '`';
        }
        &:after {
            content: '`';
        }
    }
}
