@use '../variables' as *;

// https://css-tricks.com/look-ma-no-media-queries-responsive-layouts-using-css-grid/

/* Styles for the hero image */
.hero {
    /* Photo by Florian Olivo on Unsplash */
    background: url('https://images.unsplash.com/photo-1542831371-29b0f74f9713')
        center;
    background-size: cover;
    padding: 4rem 2rem;
    /* grid styles */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(pixel-to-rem(240px), 1fr));
    align-items: center;

    &.about {
        /* Photo by Gonzalo Pedroviejo Gómez on Unsplash */
        background: url('https://images.unsplash.com/photo-1644512078177-f6708997183a')
            center;
        background-size: cover;
    }

    &.blog {
        /* Photo by Michał Kubalczyk on Unsplash */
        background: url('https://images.unsplash.com/photo-1515343480029-43cdfe6b6aae')
            center;
        background-size: cover;
    }

    & * {
        color: white;
    }

    & h1 {
        font-size: 4rem;
        padding-bottom: 1rem;
    }

    & article {
        & p {
            font-size: 1.5rem;
            font-weight: 500;
        }

        & a {
            padding: 1rem;
            margin-top: 0.75rem;
        }
    }
}

/* widgets styles */
.widgets {
    padding: 2rem;

    & ul {
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(
            auto-fit,
            minmax(pixel-to-rem(320px), 1fr)
        );
        grid-gap: 1rem;

        & li {
            border: pixel-to-rem(1px) solid $kpg-border-color;
            border-radius: 0.5rem;

            & figure {
                max-height: pixel-to-rem(220px);
                overflow: hidden;
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;
                position: relative;

                & img {
                    width: 100%;
                }

                & figcaption {
                    position: absolute;
                    bottom: 0;
                    background-color: $kpg-figcaption-background-color;
                    width: 100%;

                    & h3 {
                        color: white;
                        padding: 0.75rem;
                        font-size: 1.25rem;
                    }
                }
            }
            & p {
                font-size: 1rem;
                line-height: 1.5;
                padding: 1rem 0.75rem;
                color: $kpg-font-color;
            }
        }
    }

    & .about,
    & .competencies,
    & .experience {
        width: 95%;
        margin: 0 auto pixel-to-rem(16px);
        padding: pixel-to-rem(16px);
        box-shadow: $kpg-box-shadow-color pixel-to-rem(0px) pixel-to-rem(2px)
            pixel-to-rem(8px) pixel-to-rem(0px);
    }

    & .competencies,
    & .experience {
        & ul {
            grid-template-columns: auto auto auto auto;
            padding: 0;

            & li {
                border: none;
                border-radius: 0;
            }
        }
    }

    & .experience {
        & ul {
            grid-template-columns: 28% 24% 24% 24%;
        }
    }

    & .view-container {
        display: flex;
        flex-direction: row;
        text-align: center;

        & .resume {
            flex: 0 0 90%;

            & .view-pdf {
                & .pagination {
                    display: inline-block;
                }

                & .react-pdf__Page__canvas {
                    margin: 0 auto;
                }

                & .react-pdf__Page__textContent {
                    display: none;
                }

                & .react-pdf__Page__annotations.annotationLayer {
                    display: none;
                }
            }
            & .view-badge {
                display: none;
                & iframe {
                    border: none;
                }
            }
        }
    }
}

.view-post {
    & a {
        background-color: transparent;
        text-decoration: underline;
        color: -webkit-link;
        padding: 0;
        margin: 0;
        border-radius: 0;
        text-align: left;
        display: inline-block;
        transition: all 0s ease 0s;

        &:hover {
            opacity: 1;
        }
    }
}

@media (max-width: 1200px) {
    .widgets .view-container {
        flex-direction: column;
    }
    .widgets .view-container .resume .view-pdf {
        display: none;
    }
    .widgets .view-container .resume .view-badge {
        display: block;
        width: pixel-to-rem(382px);
        text-align: center;
        margin: 0 auto pixel-to-rem(16px);
    }
    .widgets .competencies ul {
        grid-template-columns: repeat(
            auto-fit,
            minmax(pixel-to-rem(320px), 1fr)
        );
    }
}

@media (max-width: 1024px) {
    .hero h1 {
        font-size: 2.5rem;
    }
    .widgets ul {
        display: block;
    }
    .widgets .about,
    .widgets .competencies,
    .widgets .experience {
        width: 100%;
    }
    .widgets .competencies ul,
    .widgets .experience ul {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .widgets .experience ul {
        text-align: center;
    }
    .widgets .view-container {
        width: 100%;
        margin: 0 auto pixel-to-rem(16px);
    }
}

@media (max-width: 414px) {
    .widgets ul {
        text-align: center;
        display: grid;
        grid-template-columns: repeat(
            auto-fit,
            minmax(pixel-to-rem(320px), 1fr)
        );
        margin-left: -24%;
    }
    .widgets .about,
    .widgets .competencies ul {
        text-align: left;
    }
    .widgets .about,
    .widgets .competencies,
    .widgets .experience {
        margin-left: pixel-to-rem(-16px);
    }
    .widgets .competencies ul,
    .widgets .experience ul {
        margin-left: 0.15rem;
    }
    .widgets .view-container .resume .view-badge {
        width: 100%;
        margin-left: -12%;
    }
}

@media (max-width: 399.98px) {
    .widgets ul {
        margin-left: -36%;
    }
    .widgets .view-container .resume .view-badge {
        width: 100%;
        margin-left: -20%;
    }
}

@media (max-width: 280px) {
    .hero {
        padding: 1rem 0.5rem;
        display: block;
        grid-template-columns: repeat(
            auto-fit,
            minmax(pixel-to-rem(240px), 1fr)
        );
        align-items: center;
    }
    .widgets ul {
        display: grid;
        grid-template-columns: repeat(
            auto-fit,
            minmax(pixel-to-rem(240px), 1fr)
        );
        margin-left: -56%;
    }
    .widgets .about,
    .widgets .competencies,
    .widgets .experience {
        margin-left: pixel-to-rem(-16px);
    }
    .widgets .about ul,
    .widgets .competencies ul {
        text-align: left;
    }
    .widgets .view-container .resume .view-badge {
        margin-left: -48%;
    }
}
